import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { technicalServices } from "../../repos/apiServices2";
import { portalServices as portalServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { getRegionDisplayByName } from "../../repos/utilities";
import { isUnderWarrantyFromStatus } from "../../repos/generatorUtilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { JobStatusBadge } from "./JobStatusBadge";
import { ServiceProfileStatus } from "./ServiceProfileStatus";
import { NullBlankValue } from "./NullBlankField";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";

import "./ServiceProfilePage.css";
import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";


export function ServiceProfilePage() {
  //#region States
  const [customerId, setCustomerId] = useState(null);
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [serviceProfile, setServiceProfile] = useState(null);
  const [portalUserId, setPortalUserId] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessTypeName, setBusinessTypeName] = useState("");
  const [address, setAddress] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [nrc, setNrc] = useState("");
  const [designation, setDesignation] = useState("");

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingGenerators, setIsLoadingGenerators] = useState(false);
  const [serviceLocations, setServiceLocations] = useState([]);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let uid = auth.getUserId();
    setCustomerId(uid['cid']);
    setServiceProfileId(uid['pid']);
    setPortalUserId(uid['uid']);
    // setUserId(uid['id']);

    fetchCustomerServiceProfile(uid['cid'], uid['pid'], uid['uid']);
  }, []);

  const fetchCustomerServiceProfile = (customerId, serviceProfileId, portalUserId) => {
    setIsLoadingProfile(true);
    let params = {
      'customer_id': customerId,
      'service_profile_id': serviceProfileId,
    };
    technicalServices.fetchCustomerServiceProfile(params)
      .then((response) => {
        let _serviceProfile = response['data'];
        setServiceProfile(_serviceProfile);
        prepareCustomerDetails(_serviceProfile);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingProfile(false);
      });

    setIsLoadingGenerators(true);
    portalServices2.fetchPortalUserPermissions(serviceProfileId, portalUserId)
      .then((response) => {
        let _responseData = response['data'];
        setServiceLocations(_responseData);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingGenerators(false);
      });
  }

  const handleErrorResponse = (error) => {
    let errorResponse = error['response'];
    if (errorResponse) {
      if (errorResponse.status === 404 || errorResponse.status === 400) {
        navigate(routes.jobHistories.url);
      }
    }
  }

  const prepareCustomerDetails = (serviceProfile) => {
    if (!serviceProfile) {
      return;
    }
    setCustomerName(serviceProfile['customerName']);
    setCompanyName(serviceProfile['companyName']);
    setBusinessTypeName(serviceProfile['businessTypeName']);
    setAddress(serviceProfile['address']);
    setContactName(serviceProfile['contactFullName']);
    setContactNo(serviceProfile['contactNo']);
    setNrc(serviceProfile['nrc']);
    setDesignation(serviceProfile['designation']);
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    fetchCustomerServiceProfile(customerId, serviceProfileId, portalUserId);
  }

  const onNewLocationClicked = (ev) => {
    setTimeout(() => {
      console.log("TODO(yemon): Navigate to New Location screen?!");
    }, 200);
  }

  const onNewCommissionClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.newGeneratorCommission.url, {
        state: {
          'customerId': customerId,
          'profileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onManageUsersClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.managePortalUsers.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onGeneratorRowClicked = (ev, generator) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'generatorId': generator['id'],
          'profileId': serviceProfileId,
          'customerId': customerId,
        }
      });
    }, 200);
  }
  //#endregion

  //#region Utilities
  const isLoading = () => {
    return isLoadingProfile || isLoadingGenerators;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfile.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{customerName}</h1>
          </div>

          <div className={"form-section"}>
            <h2>Customer & Contact Details</h2>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Customer Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Company:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Business Type:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Address:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {customerName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={companyName} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {businessTypeName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={address} />
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Contact No:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Contact NRC:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Contact Designation:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {contactName}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {contactNo}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={nrc} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={designation} />
                </ReadonlyField>
              </div>
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Profile Details</h2>

            <div className={"entry-form"}>
              <div className={"form-label"}>
                <label>
                  Profile Status:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceProfile &&
                    <ServiceProfileStatus profileStatus={serviceProfile['status']}
                                          profileActiveDatetime={serviceProfile['activeDatetime']}
                                          profileInactiveDatetime={serviceProfile['inactiveDatetime']}
                    />
                  }
                </ReadonlyField>
              </div>
            </div>
          </div>

          <div className={"form-section-controls"}>
            <h2>Commissioned Generators</h2>

            <div className={"form-button-controls"}>
              <div className={"left-side"}>
                <button className={"btn btn-secondary right-margin"} disabled={isLoading()}
                        onClick={onRefreshClicked}>
                  {isLoading() && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isLoading() && <i className="fa-solid fa-rotate"></i>}
                  Refresh
                </button>
              </div>

              <div className={"right-side right-aligned"}>
              </div>
            </div>
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <tr>
                <th scope={"col"} className={"index-col-head"}>#</th>
                <th scope={"col"}>Generator Serial</th>
                <th scope={"col"}>Generator Model</th>
                <th scope={"col"}>T&C/Installation Date</th>
                <th scope={"col"}>Warranty Status</th>
              </tr>
              </thead>
              <tbody>
              {isLoading() && <TableLoadingIndicator colspan={5} />}

              {serviceLocations && serviceLocations.length > 0 && !isLoading() &&
                serviceLocations.map((serviceLocation, index) =>
                  <ServiceLocationRow serviceLocation={serviceLocation} rowIndex={index} index={index}
                                      key={serviceLocation['id']} onRowClicked={onGeneratorRowClicked}
                  />
                )
              }

              {!serviceLocations || (serviceLocations.length === 0 && !isLoading() &&
                  <TableEmptyRow colSpan={5} />
              )}
              </tbody>
            </table>
          </div>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const ServiceLocationRow = ({
                              serviceLocation, rowIndex, index,
                              onRowClicked,
                            }) => {
  return (
    <>
      <tr className={"highlight"}>
        <td className={"index-col"}>{index + 1}</td>
        <td>
          <span title={"Location"}>
            {serviceLocation['name']}
          </span>
        </td>
        <td colSpan={3}>
          <span title={"City / Township"}>
            {getRegionDisplayByName(serviceLocation['cityName'], serviceLocation['townshipName'])}
          </span>
        </td>
      </tr>
      {serviceLocation && serviceLocation['generators'] && serviceLocation['generators'].length > 0 &&
        serviceLocation['generators'].map((generator, index) =>
          <ServiceGeneratorRow generator={generator} rowIndex={rowIndex} index={index}
                               key={generator['id']} onRowClicked={onRowClicked} />
        )
      }
    </>
  )
}

const ServiceGeneratorRow = ({
                               generator, rowIndex, index,
                               onRowClicked,
                             }) => {
  return (
    <tr>
      <td className={"index-col"}>
        {`${rowIndex + 1}.${index + 1}`}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           onClick={(ev) => onRowClicked(ev, generator)}>
          {generator['generatorSerial']}
        </a>
      </td>
      <td>
        {generator['gensetModel']}
      </td>
      <td>
        <Moment date={generator['commissionDate']} format={formatters.datetimeShort} />
      </td>
      <td>
        {refs.inventory.serviceGeneratorWarrantyStatus[isUnderWarrantyFromStatus(generator['warrantyStatus'])]}
      </td>
    </tr>
  )
}
