import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import Chart from "chart.js/auto";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { authService } from "../../repos/apiServices";
import { portalServices as portalServices2 } from "../../repos/apiServices2";

import { ProgressBar } from "../shared/ProgressBar";
import { progressTypes } from "../shared/ProgressBar";
import { isUnderWarrantyFromStatus } from "../../repos/generatorUtilities";
import { ErrorMessage } from "../shared/FormMessages";
import { DashboardCustomizeControls } from "./DashboardCustomizeControls";

import "./DashboardWidget.css";
import "./GeneratorSummaryWidget.css";

const warrantyHoursValidity = refs.inventory.serviceGeneratorWarrantyHoursValidity;
const serviceTypes = refs.inventory.serviceTypeShort;

export const GeneratorSummaryWidget = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(true);
  const [customerId, setCustomerId] = useState(null)
  const [profileId, setProfileId] = useState(null);
  const [portalUserId, setPortalUserId] = useState(null);
  const [pinnedGeneratorIds, setPinnedGeneratorIds] = useState([]);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [widgetGenerators, setWidgetGenerators] = useState([]);
  const [jobYear, setJobYear] = useState("(2024)");

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    const uid = auth.getUserId();
    setPortalUserId(uid['uid']);
    fetchGeneratorSummaries(uid['uid']);
  }, []);

  const fetchGeneratorSummaries = (portalUserId) => {
    setIsLoading(true);
    portalServices2.fetchGeneratorSummaries(portalUserId)
      .then((response) => {
        let _responseData = response['data'];

        const _pinnedGeneratorIds = _responseData['pinnedGeneratorIds'];
        const _allowedServiceLocations = _responseData['allowedLocations'];
        console.log(_allowedServiceLocations);
        setPinnedGeneratorIds(_pinnedGeneratorIds);
        setServiceLocations(_allowedServiceLocations ?? []);
        setCustomerId(_responseData['customerId']);
        setProfileId(_responseData['profileId']);

        if (_pinnedGeneratorIds.length === 0) {
          setWidgetGenerators([]);
        } 
        else {
          let _pinnedGenerators = [];
          for (let _location of _allowedServiceLocations) {
            for (let _generator of _location['generators']) {
              if (_pinnedGeneratorIds.some((id) => id === _generator['id'])) {
                _pinnedGenerators.push(_generator);
              }
            }
          }
          setWidgetGenerators(_pinnedGenerators);
        }
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleErrorResponse = (error) => {
    let errorResponse = error['response'];
    if (errorResponse) {
      if (errorResponse.status === 404 || errorResponse.status === 400) {
        navigate(routes.jobHistories.url);
      }
    }
  }
  //#endregion

  //#region Control handlers
  const getTitleLabel = () => {
    if (widgetGenerators && widgetGenerators.length > 1) {
      return "Generators Summary";
    } 
    else {
      return "Generator Summary";
    }
  }

  const onRefreshClicked = (ev) => {
    const uid = auth.getUserId();
    fetchGeneratorSummaries(uid['uid']);
  }
  
  const onSettingsSaved = () => {
    const uid = auth.getUserId();
    fetchGeneratorSummaries(uid['uid']);
  }
  //#endregion

  //#region Utilities
  const isGeneratorPinned = (generatorId) => {
    if (pinnedGeneratorIds.length === 0) {
      return false;
    }
    return pinnedGeneratorIds.some((id) => id === generatorId);
  }
  //#endregion

  //#region Render
  return (
    <div className={"content-area"}>
      <div className={"row"}>
        <h1>
          {getTitleLabel()}
        </h1>
      </div>

      <div className={"dashboard-widget"}>
        <div className={"widget-floated-controls"}>
          <button className={"btn btn-secondary"} disabled={isLoading}
            onClick={onRefreshClicked}>
            {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isLoading && <i className="fa-solid fa-rotate"></i>}
            Refresh
          </button>
        </div>

        <div className={"summary-widgets-container"}>
          {isLoading && <WidgetLoadingIndicator />}

          {!isLoading && widgetGenerators.length > 0 && 
            widgetGenerators.map((generator, index) => 
              <GeneratorSummary key={generator['id']} index={index}
                                customerId={customerId} profileId={profileId}
                                generator={generator} isLoading={isLoading}
                                jobYear={jobYear} />
            )
          }

          {!isLoading && widgetGenerators.length === 0 && 
            <EmptyWidgetIndicator />
          }
        </div>

      </div>

      <DashboardCustomizeControls portalUserId={portalUserId} isLoading={isLoading}  setIsLoading={setIsLoading}
                                  serviceLocations={serviceLocations} isGeneratorPinned={isGeneratorPinned} 
                                  pinnedGeneratorIds={pinnedGeneratorIds} setPinnedGeneratorIds={setPinnedGeneratorIds} 
                                  onSettingsSaved={onSettingsSaved} />
    </div>
  )
  //#endregion
}

const GeneratorSummary = ({ customerId, profileId, generator, isLoading, jobYear }) => {
  return (
    <div className={"summary-widget"}>
      <div className={"left"}>
        <WarrantyStatusChart customerId={customerId} profileId={profileId} 
                             generator={generator} isLoading={isLoading} />
      </div>
      <div className={"right"}>
        <h5>Jobs Summary of {generator['jobHistorySummary'] && generator['jobHistorySummary']['jobYear']}</h5>
        <JobHistorySummaryChart jobHistorySummary={generator['jobHistorySummary']} />
      </div>
    </div>
  )
}

const WarrantyStatusChart = ({ customerId, profileId, generator, isLoading, }) => {
  const [isUnderWarranty, setIsUnderWarranty] = useState(true);
  const [progressType, setProgressType] = useState(progressTypes.default);

  const navigate = useNavigate();

  useEffect(() => {
    const _isUnderWarranty = isUnderWarrantyFromStatus(generator['warrantyStatus']);
    setIsUnderWarranty(_isUnderWarranty);
    if (_isUnderWarranty) {
      setProgressType(progressTypes.default);
    } 
    else {
      setProgressType(progressTypes.error);
    }
  }, []);

  const onViewClicked = (ev, generator) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'generatorId': generator['id'],
          'profileId': profileId,
          'customerId': customerId,
        }
      });
    }, 200);
  }

  const getOverWarrantyDescription = (warrantyStatus) => {
    const reasons = [];
    if (warrantyStatus.withinValidDate === false) {
      reasons.push("overdue date");
    }
    if (warrantyStatus.runHoursValidity === warrantyHoursValidity.over) {
      reasons.push("exceeded run hours");
    }
    if (reasons.length > 1) {
      return reasons.join(" and ");
    } else {
      return reasons[0];
    }
  }

  return (
    <>
      <h2>[{generator['gensetModel']}] {generator['generatorSerial']}</h2>
      <h5>Warranty Status</h5>
      {generator['warrantyMonths'] && 
        <>
          <div>
            Duration Months:
          </div>
          <ProgressBar tooltipLabel={"months"} isFormatted={true} type={progressType}
                       progressAmount={generator['warrantyPassedMonths'] ?? 0} 
                       totalAmount={generator['warrantyMonths']} />
        </>
      }
      {generator['warrantyHours'] && 
        <>
          <div>
            Run Hours:
          </div>
          <ProgressBar tooltipLabel={"hours"} isFormatted={true} type={progressType}
                       progressAmount={generator['lastRunHours']} 
                       totalAmount={generator['warrantyHours']} />
        </>
      }
      {isUnderWarrantyFromStatus(generator['warrantyStatus']) === false && 
        <div style={{ 'marginTop': '8px' }}>
          <ErrorMessage>
            Over-warranty by {getOverWarrantyDescription(generator['warrantyStatus'])}.
          </ErrorMessage>
        </div>
      }
      <div className={"controls"}>
        <button role={"button"} className={"btn btn-secondary"} 
                disabled={isLoading} onClick={(ev) => onViewClicked(ev, generator)}>
          <i className={"fa-solid fa-search"}></i>
          View
        </button>
      </div>
    </>
  )
}

const JobHistorySummaryChart = ({ jobHistorySummary }) => {
  const [jobYear, setJobYear] = useState("");
  const [isEmptyJobs, setIsEmptyJobs] = useState(false);

  const canvasRef = useRef();

  useEffect(() => {
    initChart();
  }, []);

  const initChart = () => {
    let data = [];
    if (!jobHistorySummary || !jobHistorySummary['summaries'] || jobHistorySummary['summaries'].length === 0) {
      data = [];
    } else {
      data = jobHistorySummary['summaries'];
    }
    setJobYear(jobHistorySummary['jobYear']);
    setIsEmptyJobs(data.length === 0);

    new Chart(canvasRef.current, 
      {
        type: 'doughnut',
        options: {
          animation: false,
        },
        data: {
          labels: data.map(summary => serviceTypes[summary['type']]),
          datasets: [
            { 
              label: "Jobs",
              data: data.map(summary => summary['total']),
            }
          ],
          hoverOffset: 4,
          borderWidth: 4,
          borderRadius: 12,
        },
      }
    );
  }

  if (!isEmptyJobs) {
    return (
      <div className={"job-history-summary-chart"}>
        <canvas ref={canvasRef} id="job-history-chart"></canvas>
      </div>
    )
  }
  else {
    return (
      <div className={"job-history-summary-empty"}>
        No service jobs within the year {jobYear}.
      </div>
    )
  }
}

const WidgetLoadingIndicator = () => {
  return (
    <div className={"widget-loading-indicator"}>
      <i className={"fa-solid fa-circle-notch fa-spin"}></i>
    </div>
  )
}

const EmptyWidgetIndicator = () => {
  return (
    <div className={"widget-empty-indicator"}>
      <div className={"widget-empty-icon"}>
        <i className={"fa-solid fa-folder-open"}></i>
      </div>
      <div className={"widget-empty-message"}>
        You are not allowed to view any commissioned generators in your organization.<br/>
        Contact the <b>Customer Care</b> if you need assistance.
      </div>
    </div>
  )
}
