import React from "react";
import { useState } from "react";

import { delayedClick } from "../../repos/clicker";
import { portalServices as portalServices2 } from "../../repos/apiServices2";

import { ModalContainer } from "../shared/ModalContainer";
import { modalSizes } from "../shared/ModalContainer";
import { DASHBOARD_ALLOWED_GENERATORS } from "../../repos/constants";

import "./DashboardCustomizeControls.css";
import "../shared/ModalControlsGrid.css";

export const DashboardCustomizeControls = ({ 
                                            portalUserId, isLoading, setIsLoading,
                                            serviceLocations, isGeneratorPinned,
                                            pinnedGeneratorIds, setPinnedGeneratorIds,
                                            onSettingsSaved,
                                          }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [originalPinnedGeneratorIds, setOriginalPinnedGeneratorIds] = useState([]);

  const onCustomizeClicked = (ev) => {
    if (pinnedGeneratorIds.length === 0) {
      setOriginalPinnedGeneratorIds([]);
      delayedClick(setIsModalOpened(true));
      return;
    }
    else {
      const _generatorIds = pinnedGeneratorIds.slice();
      _generatorIds.sort();
      setOriginalPinnedGeneratorIds(_generatorIds);

      delayedClick(setIsModalOpened(true));
    }
  }

  const arePinsDifferent = () => {
    if (originalPinnedGeneratorIds.length !== pinnedGeneratorIds.length) {
      return true;
    }
    const _pinnedGeneratorIds = pinnedGeneratorIds.slice();
    _pinnedGeneratorIds.sort();
    for (let i = 0; i < _pinnedGeneratorIds.length; i += 1) {
      if (_pinnedGeneratorIds[i] !== originalPinnedGeneratorIds[i]) {
        return true;
      }
    }
    return false;
  }

  const onModalCloseClicked = (ev) => {
    onSaveClicked(ev);
  }

  const onSaveClicked = (ev) => {
    setIsModalOpened(false);
    if (!arePinsDifferent()) {
      return;
    }

    setIsLoading(true);
    const payload = {
      'portalUserId': portalUserId,
      'pinnedGeneratorIds': pinnedGeneratorIds,
    };
    portalServices2.postDashboardSettings(payload)
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
        if (onSettingsSaved) {
          onSettingsSaved();
        }
      });
  }

  return (
    <div className={"dashboard-customize-controls"}>
      <CustomizeModal isOpen={isModalOpened} onRequestClose={onModalCloseClicked}
                      serviceLocations={serviceLocations} isGeneratorPinned={isGeneratorPinned} 
                      pinnedGeneratorIds={pinnedGeneratorIds} setPinnedGeneratorIds={setPinnedGeneratorIds} 
                      onSaveClicked={onSaveClicked} />

      <button type={"button"} className={"btn btn-secondary"}
              disabled={isLoading || serviceLocations.length === 0} onClick={onCustomizeClicked}>
        <i className={"fa-solid fa-gear"}></i>
        Customize...
      </button>
    </div>
  )
}

const CustomizeModal = ({ 
                          isOpen, onRequestClose, 
                          serviceLocations, isGeneratorPinned, 
                          pinnedGeneratorIds, setPinnedGeneratorIds,
                          onSaveClicked,
                        }) => {
  const [isSaving, setIsSaving] = useState(false);

  const isEmpty = () => {
    if (!serviceLocations || serviceLocations.length == 0) {
      return true;
    }
    return false;
  }

  return (
    <ModalContainer elementId={"dashboard-customize-modal"}
                    isOpen={isOpen} onRequestClose={onRequestClose} 
                    isLoading={false} modalSize={modalSizes.tiny}
                    height={720} title={"Customize Your Dashboard"}
                    shortDescription={`Select up to ${DASHBOARD_ALLOWED_GENERATORS} generators to pin to the dashboard.`}>
      <div className={`generators-grid ${isEmpty() ? "no-border" : ""}`}>
        {isEmpty() && 
          <div className={"empty-indicator"}>
            No service generators to customize.
          </div>
        }

        {!isEmpty() && serviceLocations.length > 0 && 
          serviceLocations.map((location, i) => 
            <div key={location['id']} index={i}>
              <div className={"location-group"}>
                {location['name']}
              </div>
              <div className={"location-generators"}>
                {location['generators'].length > 0 && location['generators'].map((generator, j) => 
                  <GeneratorGridButton key={generator['id']} index={j} 
                                       generator={generator} disabled={false} isGeneratorPinned={isGeneratorPinned} 
                                       pinnedGeneratorIds={pinnedGeneratorIds} setPinnedGeneratorIds={setPinnedGeneratorIds} />)
                }
              </div>
            </div>
          )
        }
      </div>

      <div className={"modal-controls"}>
        <button type={"button"} className={"btn btn-primary"} disabled={isEmpty()}
                onClick={onSaveClicked}>
          {isSaving && <i className="fa-solid fa-circle-notch fa-spin"></i>}
          {!isSaving && <i className={"fa-solid fa-check"}></i>}
          Save
        </button>
      </div>
    </ModalContainer>
  )
}

const GeneratorGridButton = ({ 
                              generator, disabled, isGeneratorPinned, 
                              pinnedGeneratorIds, setPinnedGeneratorIds,
                            }) => {
  const isButtonDisabled = () => {
    if (disabled) {
      return true;
    }
    return !isPinned() && pinnedGeneratorIds.length >= DASHBOARD_ALLOWED_GENERATORS;
  }

  const isPinned = () => {
    if (isGeneratorPinned === null) {
      return false;
    }
    return isGeneratorPinned(generator['id']);
  }

  const onPinButtonClicked = (ev, generatorId, pinned) => {
    const alreadyPinned = (_id) => {
      return _id === generatorId;
    };

    // NOTE(yemon): "force clone" the entire array
    let _pinnedGeneratorIds = pinnedGeneratorIds.slice();

    let _isAlreadyPinned = _pinnedGeneratorIds.some(alreadyPinned);
    if (pinned && !_isAlreadyPinned) {
      _pinnedGeneratorIds.push(generatorId);
      setPinnedGeneratorIds(_pinnedGeneratorIds);
    }

    if (!pinned && _isAlreadyPinned) {
      let existingPinIndex = _pinnedGeneratorIds.findIndex(alreadyPinned);
      if (existingPinIndex !== -1) {
        _pinnedGeneratorIds.splice(existingPinIndex, 1);
        setPinnedGeneratorIds(_pinnedGeneratorIds);
      }
    }
  }

  return (
    <button type={"button"} disabled={isButtonDisabled()} 
      onClick={(ev) => onPinButtonClicked(ev, generator['id'], !isPinned())}>
      <div className={"genset-model"}>
        {generator['gensetModel']}
      </div>
      <div className={"generator-serial"}>
        {generator['generatorSerial']}
      </div>
      <div className={"pin-indicator"}>
        {isPinned() && 
          <span className={"fa-solid fa-thumbtack"}></span>
        }
      </div>
    </button>
  )
}