import axios from "axios";

let host = "http://localhost:8001/api";
if (process.env.REACT_APP_ENV === "sit") {
  host = "/api";
} else if (process.env.REACT_APP_ENV === "staging") {
  host = "https://stage-ptimis2-38cdfed39666.herokuapp.com/api";
} else if (process.env.REACT_APP_ENV === "production") {
  host = "https://prod-ptimis2-02931404788a.herokuapp.com/api";
}

let portalHost = host + '/p';

const routes =  {
  "auth": {
  },
  "ref": {
    "businessTypes": `${host}/ref/business-types/`,
    "searchGenerators": `${host}/ref/generators/search/`,
    "searchGeneratorsOrATS": `${host}/ref/generators-ats/search/`,
    "regionsSearch": `${host}/ref/regions/search/`,
    // "salesPersonsList": `${host}/ref/sales-persons/`,
  },
  "profiles": {
  },
  "sales": {
  },
  "inventory": {
  },
  "technical": {
    // ...

    "serviceProfileDetails": `${host}/service/service-profile/`,
    // "serviceProfileGenerators": `${host}/service/service-profile-generators/`,
    // "serviceProfileGenerator": `${host}/service/service-profile-generator/`,
    //...
  },
  "customer": {
  },
  "portal": {
    dashboardUserSettings: `${portalHost}/dashboard-user-settings/`,
    generatorSummaries: `${portalHost}/portal-user-generators`,

    // "portalUsers": `${portalHost}/portal-users/`,
    // "portalUserSubmit": `${portalHost}/portal-user/`,
    // "portalUserResetPassword": `${portalHost}/portal-user/reset-password/`,
    // "portalUserChangePassword": `${portalHost}/portal-user/change-password/`,
    portalUsersPermissions: `${portalHost}/portal-user-permissions`,
    // "portalUserPermissions": `${portalHost}/portal-user-permissions/`,

    // "serviceGeneratorJobEntry": `${portalHost}/service-profile-generator/job-entry/`,
  },
}

const options = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
};

const getAuthenticatedOptions = () => {
  let token = localStorage.getItem('token');
  if (token == null) return options;
  let authOptions = options;
  authOptions.headers['Authorization'] = `Bearer ${token}`;
  return authOptions;
}

const getAuthenticatedBlobOptions = () => {
  let options = {
    'responseType': 'blob',
  };
  let token = localStorage.getItem('token');
  if (token == null) return options;
  options['headers'] = {
    'Authorization': `Bearer ${token}`,
  };
  return options;
}


export const authService = {}


export const refServices = {
  fetchBusinessTypes: function() {
    return axios.get(routes.ref.businessTypes, getAuthenticatedOptions());
  },

  searchGenerators: function(term) {
    return axios.get(routes.ref.searchGenerators + term, getAuthenticatedOptions());
  },

  searchGeneratorsOrATS: function(term) {
    return axios.get(routes.ref.searchGeneratorsOrATS + term, getAuthenticatedOptions());
  },

  searchRegions: function(term) {
    return axios.get(routes.ref.regionsSearch + "?term=" + term, getAuthenticatedOptions());
  },

  fetchSalesPersonsList: function(payload) {
    //return axios.post(routes.ref.salesPersonsList, payload, getAuthenticatedOptions());
  },
}


export const profilesServices = {}


export const salesServices = {}


export const inventoryServices = {}


export const technicalServices = {
  // ...

  fetchCustomerServiceProfile: function(params) {
    return axios.get(routes.technical.serviceProfileDetails + params['customer_id'] + '/' + params['service_profile_id'],
        getAuthenticatedOptions()
    );
  },

  //...
}


export const customerServices = {}


export const portalServices = {
  // ...

  fetchGeneratorSummaries: function(portalUserId) {
    return axios.get(`${routes.portal.generatorSummaries}/${portalUserId}`, getAuthenticatedOptions());
  },

  postDashboardSettings: function(payload) {
    return axios.post(`${routes.portal.dashboardUserSettings}`, payload, getAuthenticatedOptions());
  },

  // ...

  fetchPortalUserPermissions: function(serviceProfileId, portalUserId) {
    return axios.get(`${routes.portal.portalUsersPermissions}/${serviceProfileId}/${portalUserId}`, getAuthenticatedOptions());
  },


  // ...
}
